import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Korean from './languageDivide/Korean';
import English from './languageDivide/English';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Korean />}></Route>
          <Route path="/korean" element={<Korean />}></Route>
          <Route path="/english" element={<English />}></Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
