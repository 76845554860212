import React, { useEffect, useState } from "react";

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CaledarToolbar from "./CaledarToolbar";


export default function CalendarComponent(props) {

  moment.locale('ko-KR');
  const localizer = momentLocalizer(moment);

  const [value, setValue] = useState(null);

  return (
    <Calendar
      localizer={localizer}
      style={{ height: 500, width: 850 }}
      components={{
        toolbar: CaledarToolbar,
      }}
      events={props.possibleEvents}
      BackgroundWrapper="red"
      eventPropGetter={(eventsList) => {
        const backgroundColor = eventsList.colorEvent ? eventsList.colorEvent : '#5697ff';
        const color = eventsList.color ? eventsList.color : 'white';
        return { style: { backgroundColor, color } }
      }}
    />
  );

}