import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenstrualStep from './MenstrualStep';
import CalculateMenstrualPeriodEn from './CalculateMenstrualPeriodEn';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MenstrualModalEn(props) {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <p>You don't know the cycle?</p>
            <p>Please enter the date of menstruation in the previous month and 2 months before!</p>
            <CalculateMenstrualPeriodEn handleClose={props.handleClose} setSelectedPeriod={props.setSelectedPeriod} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
