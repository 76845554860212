import React, { useEffect, useState } from "react";
import { Button, Card, TextField } from "@mui/material";
import SelectPeriod from "../inputForm/SelectPeriod";
import DatePickMobile from "./DatePickMobile";
import Divider from '@mui/material/Divider';
import { Drawer, MenuItem } from "@mui/material";

import { pink } from "@mui/material/colors";
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from 'react-router-dom';

export default function InputFormMobile() {


    const [recentlyMenstruationDate, setRecentlyMenstruationDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [possibleEvents, setPossibleEvents] = useState([]);
    const [possiblePeriod, setPossiblePeriod] = useState('');
    const [ovulation, setOvulation] = useState('');
    const [nextMenstruationDate, setNextMenstruationDate] = useState('');

    const [openYn, setOpenYn] = useState(false);


    const openDrawer = () => {

        setOpenYn(!openYn);

    }

    const navigate = useNavigate();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenYn(false);
    };

    const calcDate = (e) => {

        if (selectedPeriod === '') {
            alert("생리주기를 선택해주세요");
            return;
        }

        var periodNumber = parseInt(selectedPeriod);

        //다음 생리 시작날짜 구하기 시작
        const nextMenstruationDateVariables = new Date(
            recentlyMenstruationDate.getFullYear(),
            recentlyMenstruationDate.getMonth(),
            recentlyMenstruationDate.getDate() + periodNumber
        );




        //가임기 시작일
        const ovulationStartDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 18

        );


        //가임기 종료일
        const ovulationEndDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 11
        );

        //배란일
        const ovulationDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 14
        );

        let resultData = [
            {
                title: "배란예정일",
                start: ovulationDate,
                end: ovulationDate

            },
            {
                title: "가임기",
                start: ovulationStartDate,
                end: ovulationEndDate

            },
            {
                title: "생리 시작예정일",
                start: nextMenstruationDateVariables,
                end: nextMenstruationDateVariables

            },
            {
                title: "최근 생리종료일",
                start: recentlyMenstruationDate,
                end: recentlyMenstruationDate

            },
        ]

        setPossiblePeriod(ovulationStartDate.getFullYear() + "." + (ovulationStartDate.getMonth() + 1) + "." + ovulationStartDate.getDate() + " ~ " + ovulationEndDate.getFullYear() + "." + (ovulationEndDate.getMonth() + 1) + "." + ovulationEndDate.getDate());
        // setPossibleEvents(resultData);

        setOvulation(ovulationDate.getFullYear() + "." + (ovulationDate.getMonth() + 1) + "." + ovulationDate.getDate());

        setNextMenstruationDate(nextMenstruationDateVariables.getFullYear() + "." + (nextMenstruationDateVariables.getMonth() + 1) + "." + nextMenstruationDateVariables.getDate());

    }

    return (
        <div>
            <Card style={{ width: '100%', height: '90%' }}>
                <div style={{ fontSize: '15pt', color: '#FFFFFF', background: "linear-gradient(to left top, #ff6c36,#ffb317)", height: '10%', textAlign: 'center' }}>
                    <div style={{ paddingTop: 25, paddingLeft: 25, paddingBottom: 25 }}>배란일 계산기
                        <div style={{ float: "right", paddingRight: 10, marginTop: 2 }}>
                            <LanguageIcon
                                onClick={openDrawer}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 15, marginLeft: '5%', width: '90%' }} >
                    <DatePickMobile recentlyMenstruationDate={recentlyMenstruationDate} setRecentlyMenstruationDate={setRecentlyMenstruationDate} />
                </div>
                <div style={{ marginTop: 15 }} >
                    <SelectPeriod setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} />
                </div>
                <div style={{ margin: 15 }} >
                    <Button variant="contained" onClick={calcDate}>계산</Button>
                </div>
                <Divider />
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="가임기"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={possiblePeriod}
                    />
                </div>
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="배란일"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={ovulation}
                    />
                </div>
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="다음 생리예정일"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={nextMenstruationDate}
                    />
                </div>

                <Drawer open={openYn}
                    anchor={"right"}
                    onClose={toggleDrawer("right", false)}>
                    <MenuItem onClick={() => navigate("/")}>한국어</MenuItem>
                    <MenuItem onClick={() => navigate("/english")}>English</MenuItem>

                </Drawer>
            </Card>
            <p>* 개인정보를 저장하지 않습니다</p>
        </div>
    );
}
