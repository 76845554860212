import { Box, Button, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectPeriod from "./SelectPeriod";
import DatePick from "./DatePick";
import CalendarComponent from "./CalendarComponent";
import { blue, pink } from "@mui/material/colors";
import SelectLanguage from "../lang/SelectLanguage";
import MenstrualModal from "./modal/MenstrualModal";
import HelpIcon from '@mui/icons-material/Help';

export default function InputForm() {

    const [recentlyMenstruationDate, setRecentlyMenstruationDate] = useState(new Date());
    const [nextMenstruationDate, setNextMenstruationDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [possibleEvents, setPossibleEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const calcDate = (e) => {

        if (selectedPeriod === '') {
            alert("생리주기를 선택해주세요");
            return;
        }

        var periodNumber = parseInt(selectedPeriod);

        const nextMenstruationDateVariables = new Date(
            recentlyMenstruationDate.getFullYear(),
            recentlyMenstruationDate.getMonth(),
            recentlyMenstruationDate.getDate() + periodNumber
        );

        //다음 생리 시작날짜 구하기 시작
        // nextMenstruationDateVariables.setDate(recentlyMenstruationDate.getDate() + periodNumber);

        //가임기 시작일
        const ovulationStartDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 18

        );

        //가임기 종료일
        const ovulationEndDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 11
        );


        //배란일
        const ovulationDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 14
        );

        let resultData = [
            {
                title: "배란예정일",
                start: ovulationDate,
                end: ovulationDate,
                colorEvent: '#9ed64d'


            },
            {
                title: "가임기",
                start: ovulationStartDate,
                end: ovulationEndDate,
                colorEvent: '#ff8254'

            },
            {
                title: "생리 시작예정일",
                start: nextMenstruationDateVariables,
                end: nextMenstruationDateVariables


            },
            {
                title: "최근 생리(시작)일",
                start: recentlyMenstruationDate,
                end: recentlyMenstruationDate


            },
        ]

        setPossibleEvents(resultData);

    }

    return (
        <Card style={{ width: 1000 }}>

            <div style={{ fontSize: '30pt', color: '#FFFFFF', background: "linear-gradient(to left top, #ff6c36,#ffb317)", textAlign: 'center', paddingTop: 30, paddingBottom: 30, paddingLeft: 100 }}>
                배란일 계산기
                <div style={{ float: "right" }}>
                    <SelectLanguage />
                </div>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, marginLeft : 2 }}>
                * 본 계산기는 개인정보를 저장하지 않습니다. 안심하고 사용하세요!
            </Box>
            <div style={{ marginTop: 50 }}>
                <DatePick recentlyMenstruationDate={recentlyMenstruationDate} setRecentlyMenstruationDate={setRecentlyMenstruationDate} />

                <SelectPeriod style={{ marginTop: 15, marginBottom: 15 }} setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} />

                <HelpIcon sx={{ color: blue[900] }} style={{ marginLeft: -10, marginTop: 15, cursor: "pointer" }} onClick={handleOpen} />

                <Button style={{ padding: 15, marginLeft: 30, marginTop: -15 }} variant="contained" onClick={calcDate}>계산</Button>
            </div>
            <div style={{ marginLeft: 75, marginTop: 75,  marginBottom: 15,textAlign: 'center' }}>
                <CalendarComponent possibleEvents={possibleEvents} />
            </div>
            <p>* 배란일과 가임기를 빠르고 간편하게 계산하세요!</p>
            <MenstrualModal open={open} setOpen={setOpen} handleClose={handleClose} handleOpen={handleOpen} setSelectedPeriod={setSelectedPeriod} />
        </Card>
    );
}