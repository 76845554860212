import { BrowserView, MobileView } from 'react-device-detect';
import React, { useEffect, useState } from "react";

import InputForm from '../inputForm/InputForm';

import InputFormMobile from '../mobile/InputFormMobile';


function Korean() {
    return (
        <div>
            <BrowserView>
                <InputForm />
            </BrowserView>
            <MobileView>
                <InputFormMobile />
            </MobileView>
        </div>
    );
}

export default Korean;

