import { Box, Button, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectPeriodEn from "./SelectPeriodEn";
import DatePickEn from "./DatePickEn";
import CalendarComponentEn from "./CalendarComponentEn";
import { blue, pink } from "@mui/material/colors";
import SelectLanguageEn from "../lang/SelectLanguageEn";
import MenstrualModalEn from "./modal/MenstrualModalEn";
import HelpIcon from '@mui/icons-material/Help';

export default function InputForm() {

    const [recentlyMenstruationDate, setRecentlyMenstruationDate] = useState(new Date());
    const [nextMenstruationDate, setNextMenstruationDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [possibleEvents, setPossibleEvents] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const calcDate = (e) => {

        if (selectedPeriod === '') {
            alert("Please select your menstrual cycle");
            return;
        }

        var periodNumber = parseInt(selectedPeriod);

        const nextMenstruationDateVariables = new Date(
            recentlyMenstruationDate.getFullYear(),
            recentlyMenstruationDate.getMonth(),
            recentlyMenstruationDate.getDate() + periodNumber
        );

        //가임기 시작일
        const ovulationStartDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 18

        );

        //가임기 종료일
        const ovulationEndDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 11
        );

        //배란일
        const ovulationDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 14
        );

        let resultData = [
            {
                title: "Expected ovulation date",
                start: ovulationDate,
                end: ovulationDate,
                colorEvent: '#9ed64d'


            },
            {
                title: "Fertile period",
                start: ovulationStartDate,
                end: ovulationEndDate,
                colorEvent: '#ff8254'

            },
            {
                title: "Expected date of menstruation",
                start: nextMenstruationDateVariables,
                end: nextMenstruationDateVariables


            },
            {
                title: "Last menstrual beginning date",
                start: recentlyMenstruationDate,
                end: recentlyMenstruationDate


            },
        ]

        setPossibleEvents(resultData);

    }

    return (
        <Card style={{ width: 1000 }}>
            <div style={{ fontSize: '30pt', color: '#FFFFFF', background: "linear-gradient(to left top, #ff6c36,#ffb317)", textAlign: 'center', paddingTop: 30 ,paddingBottom : 30, paddingLeft : 100}}>
                    Ovulation Calculator
                <div style={{ float: "right" }}>
                    <SelectLanguageEn />
                </div>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1, marginLeft : 2 }}>
                * This calculator does not store any personal information. Please use it with confidence!
            </Box>
            <div style={{ marginTop: 50 }}>
                <DatePickEn recentlyMenstruationDate={recentlyMenstruationDate} setRecentlyMenstruationDate={setRecentlyMenstruationDate} />

                <SelectPeriodEn style={{ margin: 15 }} setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} />
                
                <HelpIcon sx={{ color: blue[900] }} style={{ marginLeft: -10, marginTop: 15, cursor: "pointer" }} onClick={handleOpen} />

                <Button style={{ padding: 15, marginLeft: 30, marginTop: -15 }} variant="contained" onClick={calcDate}>Calculate</Button>
            </div>
            <div style={{ marginLeft: 75, marginTop: 75,  marginBottom: 15,textAlign: 'center' }}>
                <CalendarComponentEn possibleEvents={possibleEvents} />
            </div>
            <p>* Calculate ovulation and fertile days quickly and easily!</p>
            <MenstrualModalEn open={open} setOpen={setOpen} handleClose={handleClose} handleOpen={handleOpen} setSelectedPeriod={setSelectedPeriod} />
        </Card>
    );
}