import React, { useState, useEffect} from "react";

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

export default function DatePick(props) {

    const [initDate, setInitDate] = useState(new Date());

    const closeCal = () =>{

      console.log("close");
    };
    
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="최근 생리(시작)일"
        value={props.recentlyMenstruationDate}
        size="small"
        onChange={(date)=>props.setRecentlyMenstruationDate(new Date(date))}
        onCalendarClose={closeCal}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );

}