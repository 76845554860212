import React, {useEffect, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectPeriodEn(props) {

    const [period, setPeriod] = useState("");

    const handleChange = (e)=>{
        setPeriod(e.target.value); 
        props.setSelectedPeriod(e.target.value);
    };
    
    return (
        <FormControl sx={{ marginRight: 2, marginLeft : 1, minWidth: 160 }} size="big">
            <InputLabel >Menstrual cycle</InputLabel>
            <Select

                value={props.selectedPeriod}
                label="Menstrual cycle"
                onChange={handleChange}
            >
                <MenuItem value={18}>18 days</MenuItem>
                <MenuItem value={19}>19 days</MenuItem>
                <MenuItem value={20}>20 days</MenuItem>
                <MenuItem value={21}>21 days</MenuItem>
                <MenuItem value={22}>22 days</MenuItem>
                <MenuItem value={23}>23 days</MenuItem>
                <MenuItem value={24}>24 days</MenuItem>
                <MenuItem value={25}>25 days</MenuItem>
                <MenuItem value={26}>26 days</MenuItem>
                <MenuItem value={27}>27 days</MenuItem>
                <MenuItem value={28}>28 days</MenuItem>
                <MenuItem value={29}>29 days</MenuItem>
                <MenuItem value={30}>30 days</MenuItem>
                <MenuItem value={31}>31 days</MenuItem>
                <MenuItem value={32}>32 days</MenuItem>
                <MenuItem value={33}>33 days</MenuItem>
                <MenuItem value={34}>34 days</MenuItem>
                <MenuItem value={35}>35 days</MenuItem>
                <MenuItem value={36}>36 days</MenuItem>
                <MenuItem value={37}>37 days</MenuItem>
                <MenuItem value={38}>38 days</MenuItem>
                <MenuItem value={39}>39 days</MenuItem>
                <MenuItem value={40}>40 days</MenuItem>
            </Select>
        </FormControl>
    );

}