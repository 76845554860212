import React, {useEffect, useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectPeriod(props) {

    const [period, setPeriod] = useState("");

    const handleChange = (e)=>{
        setPeriod(e.target.value); 
        props.setSelectedPeriod(e.target.value);
    };
    
    return (
        <FormControl sx={{ marginRight: 2, marginLeft : 1, minWidth: 120 }} size="big">
            <InputLabel >생리주기</InputLabel>
            <Select

                value={props.selectedPeriod}
                label="생리주기"
                onChange={handleChange}
            >
                <MenuItem value={18}>18일</MenuItem>
                <MenuItem value={19}>19일</MenuItem>
                <MenuItem value={20}>20일</MenuItem>
                <MenuItem value={21}>21일</MenuItem>
                <MenuItem value={22}>22일</MenuItem>
                <MenuItem value={23}>23일</MenuItem>
                <MenuItem value={24}>24일</MenuItem>
                <MenuItem value={25}>25일</MenuItem>
                <MenuItem value={26}>26일</MenuItem>
                <MenuItem value={27}>27일</MenuItem>
                <MenuItem value={28}>28일</MenuItem>
                <MenuItem value={29}>29일</MenuItem>
                <MenuItem value={30}>30일</MenuItem>
                <MenuItem value={31}>31일</MenuItem>
                <MenuItem value={32}>32일</MenuItem>
                <MenuItem value={33}>33일</MenuItem>
                <MenuItem value={34}>34일</MenuItem>
                <MenuItem value={35}>35일</MenuItem>
                <MenuItem value={36}>36일</MenuItem>
                <MenuItem value={37}>37일</MenuItem>
                <MenuItem value={38}>38일</MenuItem>
                <MenuItem value={39}>39일</MenuItem>
                <MenuItem value={40}>40일</MenuItem>
            </Select>
        </FormControl>
    );

}