import { BrowserView, MobileView } from 'react-device-detect';
import React, { useEffect, useState } from "react";
import InputFormEn from '../inputForm/InputFormEn';
import InputFormMobileEn from '../mobile/InputFormMobileEn';


function English() {
    return (
        <div>
            <BrowserView>
                <InputFormEn />
            </BrowserView>
            <MobileView>
                <InputFormMobileEn />
            </MobileView>
        </div>
    );
}

export default English;

