import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

export default function CalculateMenstrualPeriod(props) {

    const [lastMenstrualStartDt, setLastMenstrualStartDt] = React.useState(new Date());
    const [twoMonthBeforeMenstrualStartDt, setTwoMonthBeforeMenstrualStartDt] = React.useState(new Date());

    const calcMenstrualPeriod = () => {

        const diffDate = lastMenstrualStartDt.getTime() - twoMonthBeforeMenstrualStartDt.getTime();
        
        if(Math.round(Math.abs(diffDate / (1000 * 60 * 60 * 24))) <18){
            alert("주기가 너무 짧습니다. 다시 입력해주세요");
            return;
        }

        if(Math.round(Math.abs(diffDate / (1000 * 60 * 60 * 24))) > 40){
            alert("주기가 너무 깁니다. 다시 입력해주세요");
            return;
        }

        props.setSelectedPeriod(Math.round(Math.abs(diffDate / (1000 * 60 * 60 * 24))));


        props.handleClose(false);
    }

    return (
        <div style={{ margin: 20, textAlign: "center" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>

                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DesktopDatePicker
                        label="2달 전 생리시작일"
                        value={twoMonthBeforeMenstrualStartDt}
                        size="small"
                        // onChange={(date)=>props.setRecentlyMenstruationDate(new Date(date))}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => setTwoMonthBeforeMenstrualStartDt(new Date(date))}
                    />
                    <Box style={{ width: 50 }}>

                    </Box>


                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DesktopDatePicker
                        label="직전 생리시작일"
                        value={lastMenstrualStartDt}
                        size="small"
                        // onChange={(date)=>props.setRecentlyMenstruationDate(new Date(date))}
                        // onCalendarClose={closeCal}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => setLastMenstrualStartDt(new Date(date))}
                    />
                </LocalizationProvider>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={calcMenstrualPeriod} >완료</Button>
            </Box>
        </div>
    );
}