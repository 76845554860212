import { Button, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import DatePickMobileEn from "./DatePickMobileEn";
import Divider from '@mui/material/Divider';
import SelectPeriodEn from "../inputForm/SelectPeriodEn";
import LanguageIcon from '@mui/icons-material/Language';
import { Drawer, MenuItem } from "@mui/material";

import { pink } from "@mui/material/colors";
import { useNavigate } from 'react-router-dom';

export default function InputFormMobileEn() {


    const [recentlyMenstruationDate, setRecentlyMenstruationDate] = useState(new Date());
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [possibleEvents, setPossibleEvents] = useState([]);
    const [possiblePeriod, setPossiblePeriod] = useState('');
    const [ovulation, setOvulation] = useState('');
    const [nextMenstruationDate, setNextMenstruationDate] = useState('');

    const [openYn, setOpenYn] = useState(false);


    const openDrawer = () => {

        setOpenYn(!openYn);

    }

    const navigate = useNavigate();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenYn(false);
    };



    const calcDate = (e) => {

        if (selectedPeriod === '') {
            alert("Please select your menstrual cycle");
            return;
        }

        var periodNumber = parseInt(selectedPeriod);

        const nextMenstruationDateVariables = new Date(
            recentlyMenstruationDate.getFullYear(),
            recentlyMenstruationDate.getMonth(),
            recentlyMenstruationDate.getDate() + periodNumber
        );



        //가임기 시작일
        const ovulationStartDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 18

        );


        //가임기 종료일
        const ovulationEndDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 11
        );

        //배란일
        const ovulationDate = new Date(
            nextMenstruationDateVariables.getFullYear(),
            nextMenstruationDateVariables.getMonth(),
            nextMenstruationDateVariables.getDate() - 14
        );

        let resultData = [
            {
                title: "expected ovulation",
                start: ovulationDate,
                end: ovulationDate

            },
            {
                title: "fertile period",
                start: ovulationStartDate,
                end: ovulationEndDate
            },
            {
                title: "Expected date of menstruation",
                start: nextMenstruationDateVariables,
                end: nextMenstruationDateVariables

            },
            {
                title: "Last menstrual End Date",
                start: recentlyMenstruationDate,
                end: recentlyMenstruationDate

            },
        ]

        setPossiblePeriod(ovulationStartDate.getFullYear() + "." + (ovulationStartDate.getMonth() + 1) + "." + ovulationStartDate.getDate() + " ~ " + ovulationEndDate.getFullYear() + "." + (ovulationEndDate.getMonth() + 1) + "." + ovulationEndDate.getDate());
        // setPossibleEvents(resultData);

        setOvulation(ovulationDate.getFullYear() + "." + (ovulationDate.getMonth() + 1) + "." + ovulationDate.getDate());

        setNextMenstruationDate(nextMenstruationDateVariables.getFullYear() + "." + (nextMenstruationDateVariables.getMonth() + 1) + "." + nextMenstruationDateVariables.getDate());

    }

    return (
        <div>

            <Card style={{ width: '100%', height: '90%' }}>
                <div style={{ fontSize: '15pt', color: '#FFFFFF', background: "linear-gradient(to left top, #ff6c36,#ffb317)", height: '10%', textAlign: 'center' }}>
                    <div style={{ paddingTop: 25, paddingLeft: 25, paddingBottom: 25 }}>Ovulation Calculator
                        <div style={{ float: "right", paddingRight: 5, marginTop: 3, paddingLeft: 10 }}>
                            <LanguageIcon
                                onClick={openDrawer}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 15, marginLeft: '5%', width: '90%' }} >
                    <DatePickMobileEn recentlyMenstruationDate={recentlyMenstruationDate} setRecentlyMenstruationDate={setRecentlyMenstruationDate} />
                </div>
                <div style={{ marginTop: 15 }} >
                    <SelectPeriodEn setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} />
                </div>
                <div style={{ margin: 15 }} >
                    <Button variant="contained" onClick={calcDate}>calculate</Button>
                </div>
                <Divider />
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="Fertile period"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={possiblePeriod}
                    />
                </div>
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="Expected ovulation date"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={ovulation}
                    />
                </div>
                <div style={{ width: '90%', margin: '5%' }}>
                    <TextField
                        id="outlined-basic"
                        label="Expected date of menstruation"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={nextMenstruationDate}
                    />
                </div>
                <Drawer open={openYn}
                    anchor={"right"}
                    onClose={toggleDrawer("right", false)}>
                    <MenuItem onClick={() => navigate("/")}>한국어</MenuItem>
                    <MenuItem onClick={() => navigate("/english")}>English</MenuItem>

                </Drawer>
            </Card>
            <div>
                <p style={{marginBottom : -3}}>* This calculator does not store </p>
                <p style={{marginTop : -3}}>any personal information</p>
            </div>
        </div>
    );
}