
import React, { useEffect, useState } from "react";

export default function CalendarToolbarEn(props) {
  const {
    date,
  } = props;

  const navigate = (action) => {
    props.onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={navigate.bind(null, 'TODAY')}>
          this month
        </button>
        <button
          type="button"
          onClick={navigate.bind(null, 'PREV')}
        >
          back
        </button>
        <span className="rbc-toolbar-label">{`${date.getMonth() + 1} / ${date.getFullYear()}`}</span>
        <button
          type="button"
          onClick={navigate.bind(null, 'NEXT')}
        >
          next
        </button>
      </span>
    </div>
  );
}