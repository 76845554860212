import React, { useState, useEffect} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';


export default function SelectLanguageEn(props) {

    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useState("english");

    const handleChange = (e)=>{
      
       
        if(e.target.value === 'korean'){
            setSelectedLanguage("korean");
            navigate("/");
            
        }else if (e.target.value === 'english'){
            setSelectedLanguage("english");
            navigate("/english");
            
        }
        
    };
    
    
    // useEffect(()=>{

    //     console.log("selectedLanguage : "+selectedLanguage);
        
        
    //     if(selectedLanguage === 'korean'){
    //         navigate("/");
            
    //     }else if (selectedLanguage === 'english'){
    //         navigate("/english");
            
    //     }

    // },[selectedLanguage]);

    return (
        <FormControl style={{marginRight : 10 }}>
            <InputLabel >Language</InputLabel>
            <Select

                value={selectedLanguage}
                label="Language"
                // onChange={(e)=>setSelectedLanguage(e.target.value)}
                onChange={handleChange}

            >
                <MenuItem value={"korean"} >한국어</MenuItem>
                <MenuItem value={"english"}>English</MenuItem>
        
            </Select>
        </FormControl>
    );

}